import React from 'react';
import { Amount, Product, PriceLabels, PriceLabel, CenteredLabel } from './Prices.module.css';
import formatNumber from '../../../../scripts/formatNumber';

const Prices = ({ data }) => {
  const priceLabelsArr = data.map((priceLabel, i) => (
    <div className={[PriceLabel, 'blackBor'].join(' ')} key={i}>
      <div className={[Amount, 'greenCol'].join(' ')}> <strong>{formatNumber(priceLabel[0])}+ € </strong></div>
      <div className={[Product, 'fontMiddle'].join(' ')}> {priceLabel[1]} </div>
    </div>
  ));

  return(
    <div className={[(data.length === 1 ? CenteredLabel : ''), PriceLabels, 'brownDarkBckg'].join(' ')}>
      {priceLabelsArr}
    </div>
  );
}
export default Prices;