import React, {Fragment } from 'react'
import { createId } from '../../scripts/helper';

function debounce(fn, ms, maxWidth) {
  let timer;
  let lastWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      if (lastWidth !== window.innerWidth && (lastWidth <= maxWidth || window.innerWidth <= maxWidth)) {
        fn.apply(this, arguments);
      }
      lastWidth = window.innerWidth;
    }, ms)
  };
}

// What this component does:
// - Rerenders the child if the viewportwidth got changed
// - ignores height changes
// - Triggers rerendering after resize ended 
// - maxWidth: if Viewport is higher that this value width changes don't cause rerender





class AdSenseRerenderer extends React.Component {
  keyClass = this.props.children.key;
  getAdsenseWithKeyAsClassname = () => {
    const elementWithKeyAsClassname = React.cloneElement(this.props.children, {
      ...this.props.children.props, 
      className: `${this.props.children.props.className} ${this.keyClass}`
    });
    return elementWithKeyAsClassname;
  }
  adsenseWithKeyAsClassname = this.getAdsenseWithKeyAsClassname();

  state = {
    wrappedAdsense: <Fragment key={createId(20)}>{this.adsenseWithKeyAsClassname}</Fragment>
    // wrappedAdsense: null
  }
  rerenderCount = 0; //avoid endless rerenders because of wrong adsense ads
  // checkHeightTimeout = null;
  // checkHeightInterval = null;

  debouncedHandleResize = debounce( () => {
    this.rerenderChild();
  }, 1000, this.props.maxWidth)

  
    
  rerenderChild = () => {
    this.rerenderCount++;
    if(this.rerenderCount > 10) {
      return;
    }
    console.log('adHeight: rerender count', this.rerenderCount);
    const newKeyChild = React.cloneElement(this.adsenseWithKeyAsClassname, {
      ...this.props.children.props, 
      key: createId(15)
    });
    this.setState({
      wrappedAdsense: <Fragment key={createId(20)}>{newKeyChild}</Fragment>
    });
  };

  // checkAdHeight = () => {
  //   // Ad has its key as className
  //   const adElement = document.querySelector(`.${this.keyClass}`);

  //   if(adElement) {
  //     const adHeight = adElement.style.height;
  //     console.log('adHeight', adHeight);
  //     console.log('adHeight: adElement', adElement);
  //     console.log('adHeight: keyClass', this.keyClass);
  //     if(adHeight === '0px') {
  //       this.rerenderChild();
  //     }
  //   } else {
  //     return;
  //   }
  // };

  
  
  componentDidMount() {
    window.addEventListener('resize', this.debouncedHandleResize);
    // const checkHeightTimeout = window.setTimeout(() => {this.checkAdHeight()}, 400);
    // console.log('adheight: set timout:', checkHeightTimeout)
    // this.setState({ 
    //   wrappedAdsense: <Fragment key={createId(20)}>{this.adsenseWithKeyAsClassname}</Fragment> 
    // }, this.checkAdHeight);
    
    // this.checkHeightTimeout = window.setTimeout(() =>{
    //   window.clearInterval( this.checkHeightInterval );
    // }, 10*1000)
    console.log('Rerender Component did Mount!');
  }

  componentDidUpdate() {
    // window.clearInterval( this.checkHeightInterval ); 
    // this.checkHeightInterval = window.setInterval(this.checkAdHeight, 1000);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedHandleResize);
    // window.clearTimeout( this.checkHeightTimeout );
    // window.clearInterval( this.checkHeightInterval );
  }

  render() {
    return null; // google disabled
    return this.state.wrappedAdsense;
  }
}




export default AdSenseRerenderer;