// extracted by mini-css-extract-plugin
export var DetailsOpened = "manufac-module--DetailsOpened--28a59";
export var Manufac = "manufac-module--Manufac--a3cbb";
export var blackBckg = "manufac-module--blackBckg--4ad6c";
export var blackBor = "manufac-module--blackBor--50583";
export var blackCol = "manufac-module--blackCol--c56f8";
export var brownDarkBckg = "manufac-module--brownDarkBckg--9c11b";
export var brownLightBckg = "manufac-module--brownLightBckg--10971";
export var fontMiddle = "manufac-module--fontMiddle--1a29e";
export var fontNormal = "manufac-module--fontNormal--daead";
export var fontSmall = "manufac-module--fontSmall--264a7";
export var greenCol = "manufac-module--greenCol--cf95e";
export var greyBckg = "manufac-module--greyBckg--3da6a";
export var greyCol = "manufac-module--greyCol--1e3d3";
export var redCol = "manufac-module--redCol--389e0";
export var whiteCol = "manufac-module--whiteCol--55ae9";