// extracted by mini-css-extract-plugin
export var BodyArea = "MiniPrev-module--BodyArea--85b54";
export var Bullet = "MiniPrev-module--Bullet--06058";
export var Button = "MiniPrev-module--Button--34606";
export var ButtonMobile = "MiniPrev-module--ButtonMobile--cd755";
export var HeadArea = "MiniPrev-module--HeadArea--72add";
export var Line = "MiniPrev-module--Line--2b395";
export var Name = "MiniPrev-module--Name--633c0";
export var PrevImageWrapper = "MiniPrev-module--PrevImageWrapper--643aa";
export var Products = "MiniPrev-module--Products--fd209";
export var ReviewPrev = "MiniPrev-module--ReviewPrev--10c71";
export var ReviewPrevClickArea = "MiniPrev-module--ReviewPrevClickArea--de17e";
export var linkArrow = "MiniPrev-module--linkArrow--53e31";
export var notClickable = "MiniPrev-module--notClickable--13b5a";
export var sheen = "MiniPrev-module--sheen--142f3";