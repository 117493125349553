import React from 'react';
import * as style from './AnimatedSize.module.scss';
/* 
4 use case for size
- just length -> show length
- just usable space -> show usable space
- both -> animate both
- nothing -> show not available

*/

export default ({data, NA}) => {

  return (
    <>
      { data.length || !data.useSpace ? 
      <div className={[style.SizesWrapper, data.length && data.useSpace ? style.Animated : ''].join(' ')}>
        <span className="brownDarkBckg">Längen</span> 
        <span className="brownDarkBckg">{typeof data.length === "undefined" || data.length ==='' ? NA : data.length}</span>
      </div> : null }

      { data.useSpace ? 
      <div className={style.SizesWrapper}>
        <span className="brownDarkBckg">Nutzfläche</span> 
        <span className="brownDarkBckg">{data.useSpace}</span>
      </div> : null }
    </>
  )
}