import React from 'react';
import * as style from './manufacPrev.module.scss';
import StarRatings from 'react-star-ratings';
import InfoPopup from '../../UI/InfoPopup/InfoPopup';
import Features from './Features/Features';
import MediaQuery from 'react-responsive'
import ExplanationTxt from './ExplanationTxt';
import Basics from './Basics/Basics';
import AddressBlock from './AddressBlock/AddressBlock';
import LeftArea from './LeftArea/LeftArea';
import { replaceUmlautWhitspace } from '../../../scripts/helper';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCaretRight } from '@fortawesome/free-solid-svg-icons';

import Button from '../../UI/Button/Button';

class ManufacPrev extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showExplanations: false
    }
    this.showExplanationsHandler = this.showExplanationsHandler.bind(this);
    this.hideExplanationsHandler = this.hideExplanationsHandler.bind(this);
  }

  showExplanationsHandler(){
    this.setState({showExplanations: true});
  }

  hideExplanationsHandler(){
    this.setState({showExplanations: false});
  }

  render() {
    const {data, reviewsAmount, averageRating, prevImage} = this.props;    
    const products = data.products.map((product, i) =>
      <li key={i}><span className={[style.Bullet, "blackBckg"].join(' ')}></span><span>{product}</span></li>
    );

    const averageStars = null;
      // <StarRatings
      //   starDimension="15px"
      //   starSpacing="1px"
      //   rating={averageRating}
      //   starRatedColor="rgb(145, 73, 66)"
      //   starEmptyColor="grey"
      //   numberOfStars={5}
       
      // />;
    
    const manufacNameUrl= replaceUmlautWhitspace(data.name.toLocaleLowerCase());
    
    const leftArea = 
      <LeftArea
        prevImage={prevImage}
        data={this.props.data.prices}
        manufacName={data.name}
        manufacNameUrl={manufacNameUrl}
      />;

    const reportsString = `Erfahrungsbericht${reviewsAmount>1 || reviewsAmount === 0 ? 'e' : ''}`;
    return (
      <div 
        className={[style.ManufacPrev, this.props.show ? this.props.showDetailsClass : null,].join(' ')}
      >
        <span className={style.Anchor} id={`hersteller-${manufacNameUrl}`}></span>

        {!this.props.queryMatches ? leftArea : null}
        
        <div className={[style.HeadArea, 'fontMiddle'].join(' ')}>
          <h2 className={style.Name}>{data.name}</h2>
          <div className={style.ReviewPrev} >
            {averageStars} 
            <Link 
              to={`/hersteller/${manufacNameUrl}/#hersteller-review`}
              className={style.ReviewPrevClickArea}
            > 
              {reviewsAmount} {reportsString}
            </Link>
          </div>
          <AddressBlock data={data}/>
          <div className={style.Line}></div>
          <ul className={style.Products}>{products}</ul>
        </div>

        {this.props.queryMatches ? leftArea : null}

        <Basics clickHandler={this.showExplanationsHandler} data={data} />
        <Features featuresArr={this.props.data.features}/>
        <InfoPopup show={this.state.showExplanations} clickHandler={this.hideExplanationsHandler}>
          <ExplanationTxt />
        </InfoPopup>
        <Link 
          to={`/hersteller/${manufacNameUrl}/#top`}
          className={style.Button}
        > 
          <span className={style.linkArrow}><FontAwesomeIcon  icon={faCaretRight} /></span><span> Zur Detailseite</span>
        </Link>
      </div>

      
    );
  }
} 

export default props => (
  <MediaQuery maxWidth="785px">
    {queryMatches => <ManufacPrev queryMatches={queryMatches} {...props}/>}
  </MediaQuery>);
