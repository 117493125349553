import React from 'react';
import Prices from '../Prices/Prices';
import Img from 'gatsby-image';
import * as style from './LeftArea.module.scss';
import { Link } from 'gatsby';

export default (props) => {
  const hasPlaceholder = props.prevImage.placeholder;
  return (
    <div className={[style.LeftArea, hasPlaceholder ? style.hasPlaceholder : ''].join(' ')}>
      <Link
          to={`/hersteller/${props.manufacNameUrl}/#top`}
        > 
      <Img fluid={props.prevImage} className={style.MainImage} alt={`Tiny House von ${props.manufacName}`}/>
      </Link>
      { hasPlaceholder ? 
        <p className={style.ExampleImg}><strong>Beispielfoto</strong></p>
        : null 
      }
      <Prices data={props.data}/>
    </div>
  )
};
