import React from 'react';
import * as style from './AddressBlock.module.scss';
import ImgLocation from '../../../../images/icons/location.svg';


const AddressBlock = ({data}) => {
  let address = data.addressDisplayed;
  if(!address) {
    address = /\d{3,6}\s[A-Za-zäÄöÖüÜß]+\s*[A-Za-zäÄöÖüÜß]*/gm.exec(data.address)[0]; // extract zip and city
  }

  return (
    <div className={[style.AddressBlock, "clearfix"].join(' ')}>
      <img alt="address" className={style.Icon} src={ImgLocation}/>
      <div className={style.Address}> { address } </div> 
      {/* <a href={gMapUrl(data.address)} target="blank" rel="noopener" className={style.Link}>auf Karte anzeigen</a> */}
    </div>
  );
}


export default AddressBlock;