import React from "react"

const hs = ({ className }) => (
  <svg
    version="1.1"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="597.141px"
    height="643.737px"
    viewBox="155.225 0.324 597.141 643.737"
    enableBackground="new 155.225 0.324 597.141 643.737"
  >
    <title>Hersteller-Icon</title>
    <path
      fill="#282828"
      d="M701.809,555.322c-0.623,2.745-1.038,5.557-1.888,8.228c-5.312,16.747-16.729,26.947-33.75,30.854
	c-3.104,0.634-6.269,0.915-9.436,0.84c-137.285,0.038-274.57,0.038-411.855,0c-12.983,0-24.107-4.548-33.024-14.068
	c-6.522-6.889-10.608-15.724-11.634-25.155c-2.66-22.098,12.342-43.242,34.761-48.017c2.546-0.545,5.142-0.823,7.746-0.83
	c138.776-0.038,277.555-0.038,416.338,0c13.209,0,24.022,5.831,32.523,15.861c5.629,6.594,9.083,14.767,9.889,23.399
	c0,0.217,0.217,0.425,0.33,0.632V555.322z M451.053,526.562c-68.52,0-137.04,0-205.56,0c-1.99-0.032-3.979,0.106-5.944,0.415
	c-15.238,2.689-24.23,19.324-18.145,33.703c3.689,8.737,12.068,15.097,24.155,15.097c136.851-0.05,273.702-0.068,410.553-0.057
	c2.296,0.008,4.586-0.204,6.841-0.632c15.87-3.057,24.381-22.145,16.088-36.015c-5.067-8.492-12.682-12.492-22.438-12.502
	C588.096,526.552,519.579,526.55,451.053,526.562z"
    />
    <path
      fill="#282828"
      d="M499.023,67.614c1.802,0.358,3.604,0.727,5.416,1.076c4.905,1.059,9.578,2.996,13.794,5.718
	c0.968,0.609,2.076,0.957,3.218,1.01c48.668,0.05,167.339,0.05,216.013,0c5.577,0,9.313,2.755,10.605,7.774
	c1.368,5.35-3.047,11.322-8.576,11.634c-1.303,0.066-2.604,0.113-3.916,0.113H534.359c3.146,8.904,3.146,18.618,0,27.523h203.665
	c5.359,0,8.954,2.547,10.379,7.312c1.68,5.613-2.651,11.992-8.492,12.067c-12.747,0.16-135.476,0.132-148.27,0.142
	c-21.463,0-42.932,0-64.406,0c-1.887,0-2.396,0.443-2.388,2.368c0.066,21.409-0.085,42.818,0.123,64.227
	c0.102,5.994-0.995,11.948-3.227,17.513c-5.407,13.597-10.492,27.362-15.653,41.025c-0.415,1.089-0.642,2.24-0.67,3.406
	c-0.057,5.85-0.208,11.709,0,17.55c0.292,7.265-4.973,11.011-9.587,11.068c-5.803,0.066-9.936-4.36-10.029-10.898
	c-0.066-4.718-0.142-9.436,0-14.153c0.084-3.467-0.56-6.912-1.888-10.115c-5.661-14.625-11.24-29.284-16.738-43.979
	c-0.653-1.729-0.995-3.559-1.01-5.406c-0.065-24.022-0.113-48.055,0-72.067c0.165-3.013-0.85-5.971-2.83-8.247
	c-6.605-8.001-9.369-17.39-8.889-27.806c0.907-16.413,11.535-30.695,26.995-36.279c3.057-1.085,6.359-1.481,9.539-2.198
	c0.415-0.095,0.821-0.236,1.236-0.358L499.023,67.614z M474,108.762c-0.021,11.917,9.623,21.596,21.541,21.616
	c11.917,0.021,21.596-9.624,21.616-21.541c0.021-11.907-9.606-21.58-21.513-21.616c-11.854-0.1-21.545,9.431-21.645,21.285
	C474,108.591,473.999,108.676,474,108.762z M505.307,148.806c-6.398,1.352-13.004,1.393-19.418,0.122
	c-0.092,0.195-0.158,0.401-0.198,0.613c0,16.663-0.02,33.332-0.057,50.008c0,1.265,0.642,1.378,1.642,1.368
	c5.595,0,11.19,0,16.785,0c0.419-0.032,0.835-0.093,1.246-0.179V148.806z M488.219,220.402c2.435,6.378,4.803,12.605,7.341,19.248
	c2.528-6.652,4.897-12.879,7.312-19.248H488.219z"
    />
    <path
      fill="#282828"
      d="M414.972,244.651c49.844,0,99.694,0,149.552,0c1.48,0,2.68,0.057,3.575,1.538
	c1.151,1.887,2.52,3.623,2.341,6.086c-0.076,1.019,0.519,2.085,0.83,3.123c0.075,0.226,0.32,0.415,0.368,0.642
	c0.604,3.35,1.16,6.718,1.792,10.058c0.218,1.113,0.68,2.17,0.944,3.274c0.264,1.104,0.367,2.415,0.613,3.604
	c0.245,1.188,0.519,2.113,0.802,3.16c0.066,0.236,0.302,0.425,0.349,0.661c0.642,3.33,1.236,6.661,1.888,9.992
	c0.235,1.179,0.585,2.34,0.943,3.5c0.057,0.227,0.302,0.425,0.34,0.651c0.613,3.283,1.17,6.604,1.802,9.841
	c0.236,1.18,0.688,2.312,0.943,3.482s0.35,2.425,0.595,3.623s0.547,2.085,0.85,3.123c0.065,0.235,0.292,0.434,0.33,0.67
	c0.623,3.396,1.207,6.803,1.887,10.199c0.188,1.01,0.566,1.981,0.773,2.991c0.274,1.368,0.435,2.765,0.727,4.133
	c0.218,0.991,0.576,1.943,0.85,2.916c0.311,0.796,0.528,1.626,0.651,2.472c0.104,2.113,0.094,4.227,0.047,6.341
	c-0.071,2.669-2.245,4.802-4.916,4.821c-4.293,0-8.586,0.057-12.879,0c-1.52,0-2.058,0.5-2.058,2.066
	c0.038,39.591,0.038,79.176,0,118.754c0,1.425,0.406,2.028,1.888,2.028c15.373-0.037,30.747-0.037,46.12,0
	c1.387,0,1.83-0.575,1.812-1.887c-0.075-3.774-0.075-7.473-0.057-11.209c-0.209-2.634,1.757-4.938,4.391-5.147
	c0.231-0.019,0.464-0.02,0.695-0.005c0.812,0,1.613,0,2.425,0c2.677-0.196,5.006,1.813,5.202,4.49
	c0.019,0.252,0.018,0.505-0.003,0.756c0,8.807,0,17.585,0,26.335c0,3.047,0,6.095-0.048,9.143c0,1.35,0.435,1.953,1.821,1.887
	c2.303,0,4.604-0.085,6.906,0c0.97,0.093,1.911,0.371,2.774,0.821c1.661,0.783,2.113,2.217,1.888,3.981
	c-0.062,1.182-0.062,2.366,0,3.548c0,2.887-1.321,4.208-4.209,4.208h-32.722c-2.265-0.026-4.148-1.748-4.378-4
	c-0.071-1.305-0.071-2.612,0-3.916c0.078-2.538,2.114-4.578,4.651-4.661c2.831-0.066,5.728-0.066,8.586,0
	c1.52,0,2.114-0.49,2.048-2.066c-0.113-2.548-0.095-5.104,0-7.652c0.057-1.49-0.538-2.066-1.963-1.953c-0.434,0-0.877,0-1.312,0
	H452.581c-4.076,0-5.133-0.943-6.058-4.906c-0.24-0.571-0.737-0.995-1.34-1.142c-0.969-0.023-1.735-0.829-1.711-1.798
	c0.002-0.08,0.01-0.16,0.022-0.24c0.075-1.304,0.075-2.611,0-3.916c-0.159-1.085-0.422-2.153-0.783-3.188
	c-0.216-0.442-0.408-0.896-0.575-1.359c-0.775-4.192-2.658-8.103-5.454-11.322c-1.708-2.104-3.227-4.491-5.321-6.123
	c-2.896-2.274-5.803-4.718-9.521-5.756c-2.236-0.651-4.34-1.765-6.529-2.595c-0.591-0.188-1.211-0.269-1.83-0.236
	c-24.532,0-49.064,0-73.597,0c-0.953,0.141-1.877,0.434-2.736,0.868c-0.385,0.195-0.782,0.365-1.188,0.51
	c-6.886,1.496-13.042,5.326-17.428,10.841c-2.33,2.831-4.718,5.774-5.746,9.53c-0.622,2.236-1.792,4.321-2.595,6.52
	c-0.203,0.781-0.273,1.592-0.207,2.396c0,2.17,0,4.35,0,6.529c0,3.548-1.831,5.388-5.397,5.388h-37.741
	c-2.926,0-4.643-1.688-4.643-4.576c0-42.938,0.019-85.881,0.057-128.831c0-1.688-0.595-2.151-2.17-2.132
	c-4.604,0.065-9.209,0-13.813,0c-3.35,0-5.02-1.661-5.029-4.916c0-2.058,0-4.114,0-6.162c0-1.273-0.283-2.642,0.943-3.575
	c0.045-0.042,0.068-0.101,0.066-0.161c-0.557-3.255,1.396-6.048,1.688-9.152c0.262-1.414,0.625-2.808,1.085-4.17
	c0.198-0.83,0.227-1.698,0.416-2.529c0.349-1.528,0.755-3.047,1.142-4.566c0-0.113,0.179-0.227,0.16-0.33
	c-0.443-3.293,1.406-6.152,1.773-9.304c0.27-1.475,0.654-2.926,1.151-4.34c0-0.123,0.188-0.236,0.17-0.321
	c-0.613-3.321,1.5-6.143,1.736-9.322c0.229-1.422,0.596-2.818,1.095-4.17c0.047-0.17,0.217-0.321,0.245-0.491
	c0.557-3.17,1.066-6.359,1.67-9.521c0.301-1.275,0.666-2.535,1.095-3.773c0.057-0.17,0.227-0.321,0.255-0.491
	c0.565-3.17,1.085-6.35,1.688-9.511c0.245-1.293,0.68-2.557,1.028-3.84c0.273-0.613,0.431-1.272,0.463-1.944
	c-0.481-3.255,1.434-5.5,3.17-7.812c0.311-0.274,0.709-0.429,1.123-0.434c0.682-0.038,1.365-0.038,2.047,0L414.972,244.651z
	 M276.46,406.94c0,19.783,0,39.572,0,59.367c0,1.566,0.538,2.086,2.057,2.076c5.108-0.057,10.213-0.057,15.314,0
	c1.51,0,1.887-0.51,2.113-2.048c0.422-2.628,0.992-5.229,1.708-7.793c0.075-0.321,0.49-0.557,0.67-0.878
	c0.233-0.427,0.433-0.871,0.594-1.33c0.53-1.872,1.209-3.699,2.029-5.463c1.557-2.728,3.425-5.274,5.208-7.879
	c4.228-6.189,10.379-10.152,16.578-13.993c1.132-0.697,2.346-1.252,3.613-1.65c2.208-0.67,4.482-1.133,6.719-1.718
	c0.339-0.094,0.613-0.425,0.943-0.547c0.808-0.412,1.678-0.687,2.576-0.812c26.759-0.051,53.518-0.051,80.276,0
	c0.733,0.047,1.457,0.192,2.151,0.434c1.15,0.321,2.273,0.727,3.415,1.057c0.608,0.091,1.208,0.232,1.793,0.425
	c2.837,1.218,5.6,2.601,8.274,4.143c3.541,2.11,6.883,4.538,9.982,7.256c2.801,2.62,5.269,5.573,7.351,8.794
	c2.095,3.188,4.444,6.378,4.916,10.379c0.018,0.251,0.129,0.486,0.312,0.66c1.132,0.943,1.217,2.198,1.17,3.558
	c0.094,0.598,0.289,1.175,0.575,1.707c0.378,0.763,0.65,1.571,0.812,2.406c0.095,1.312,0.575,1.888,1.943,1.888
	c4.718-0.048,9.436,0,14.153,0c1.387,0,1.83-0.595,1.821-1.888c-0.038-19.418-0.054-38.833-0.048-58.244c0-8.216,0-16.431,0-24.646
	c-0.141-2.69,1.926-4.986,4.616-5.127c0.056-0.003,0.112-0.005,0.168-0.006c17.298,0,34.597,0,51.895,0
	c1.083,0.13,2.108,0.559,2.963,1.235c1.991,1.34,1.831,3.463,1.821,5.549c-0.038,27.048-0.066,54.118-0.085,81.21
	c0,1.34,0.434,1.888,1.821,1.888c4.661-0.048,9.331-0.066,14.002,0c1.68,0,2.076-0.689,2.076-2.256
	c-0.051-38.71-0.066-77.417-0.048-116.121c0-3.029,0-3.029-3.085-3.029H427.662c-1.24,0.085-2.486,0.085-3.727,0
	c-1.03-0.117-2.022-0.459-2.906-1c-3.52-2.378-6.963-4.868-10.426-7.341c-3.708-2.651-7.416-5.303-11.097-8.001
	c-2.877-2.123-5.661-4.35-8.586-6.435c-2.925-2.086-6.171-4.293-9.256-6.436c-1.176-0.887-2.401-1.705-3.671-2.453
	c-0.663-0.312-1.431-0.312-2.095,0c-1.887,1.189-3.613,2.566-5.396,3.888c-2.831,2.095-5.661,4.227-8.492,6.284
	c-2.651,1.887-5.388,3.688-8.02,5.604c-2.897,2.113-5.661,4.35-8.577,6.463s-5.718,3.869-8.416,6.001
	c-2.699,2.133-5.312,3.491-8.992,3.491c-16.427-0.188-32.863-0.066-49.291-0.095c-1.462,0-2.293,0.236-2.293,2.029
	C276.479,367.321,276.491,387.12,276.46,406.94z M414.906,258.7H300.624c-8.831,0-17.673,0-26.504,0
	c-1.717,0-2.321,0.566-2.227,2.293c-0.028,1.159-0.219,2.31-0.566,3.416c-0.245,1.037-0.698,2.038-0.943,3.095
	c-0.623,3.34-1.161,6.699-1.746,10.058c0,0.227-0.264,0.425-0.33,0.661c-0.34,1.15-0.735,2.292-0.943,3.472
	c-0.651,3.331-1.227,6.681-1.84,10.021c-0.047,0.236-0.273,0.425-0.34,0.66c-0.312,1.038-0.67,2.076-0.877,3.143
	c-0.642,3.388-1.227,6.794-1.84,10.19c-0.048,0.227-0.265,0.425-0.331,0.66c-0.311,1.104-0.622,2.198-0.867,3.321
	c-0.246,1.123-0.35,2.293-0.613,3.416c-0.265,1.123-0.708,2.17-0.944,3.273c-0.642,3.35-1.17,6.709-1.82,10.049
	c-0.113,0.566-0.698,1.02-0.944,1.576c-0.943,2.066-0.273,3.33,1.888,3.33c22.343,0,44.673,0,66.991,0
	c0.554,0.058,1.112-0.04,1.613-0.282c2.038-1.396,3.991-2.888,5.991-4.331c3.454-2.517,6.913-4.998,10.38-7.444
	c3.5-2.52,7.02-5.021,10.501-7.549c2.68-1.972,5.284-4.057,7.973-6.01c3.143-2.284,6.304-4.539,9.53-6.69
	c1.489-0.933,3.051-1.743,4.671-2.425c0.483-0.13,1-0.062,1.434,0.188c2.054,0.971,4.048,2.061,5.973,3.265
	c3.671,2.491,7.218,5.152,10.813,7.747c3.595,2.595,7.416,5.312,11.096,8.02c2.783,2.048,5.481,4.209,8.266,6.247
	c3.425,2.519,6.831,5.076,10.379,7.378c1.485,1.078,3.237,1.73,5.066,1.887c46.976,0.107,93.958,0.136,140.946,0.085
	c2.491,0,3.378-1.292,2.048-3.368c-0.904-1.532-1.279-3.319-1.066-5.086c0-0.547,0.094-1.283-0.208-1.622
	c-1.575-1.803-1.312-3.926-1.312-6.029c0.05-0.497-0.07-0.995-0.34-1.416c-1.349-1.368-1.198-3.038-1.198-4.718
	c-0.049-0.732-0.183-1.457-0.396-2.16c-0.35-1.528-0.746-3.048-1.095-4.576c-0.198-0.887-0.302-1.803-0.528-2.67
	c-0.293-1.104-0.793-2.161-1-3.274c-0.604-3.293-1.095-6.605-1.643-9.907c0-0.227-0.273-0.415-0.349-0.651
	c-0.378-1.265-0.821-2.52-1.076-3.774c-0.613-3.16-1.132-6.341-1.688-9.501c0-0.123-0.132-0.217-0.16-0.34
	c-0.406-1.576-0.812-3.151-1.189-4.718c-0.207-0.887-0.292-1.793-0.528-2.67c-0.422-1.058-0.737-2.154-0.943-3.274
	c-0.151-2.906-0.057-2.915-3.048-2.915L414.906,258.7z M522.819,428.16c0-11.634,0-23.268,0-34.91c0-1.623-0.51-2.208-2.132-2.199
	c-9.644,0.051-19.286,0.051-28.93,0c-1.585,0-2.188,0.481-2.188,2.151c0.05,23.324,0.05,46.652,0,69.982
	c0,1.623,0.5,2.218,2.132,2.208c9.644-0.05,19.286-0.05,28.93,0c1.585,0,2.188-0.5,2.188-2.16
	c-0.037-11.682-0.019-23.381-0.019-35.072H522.819z"
    />
    <path
      fill="#282828"
      d="M264.698,186.032c-11.954-4.113-19.503-11.945-24.239-23.645c-0.282,0.632-0.64,1.228-1.066,1.773
	c-20.833,20.871-43.472,45.7-64.33,66.533c-2.495,2.707-6.289,3.798-9.841,2.831c-3.878-0.944-6.086-3.699-7.039-7.426
	c-0.82-3.265,0.33-6.104,2.463-8.587c0.443-0.519,0.943-0.943,1.443-1.462l87.201-89.395c6.2-6.475,14.637-10.342,23.589-10.812
	c10.69-0.642,19.946,2.623,27.514,10.152c23.814,23.721,47.583,47.481,71.304,71.284c8.912,8.617,11.518,21.873,6.529,33.222
	c-0.523,0.922-0.276,2.088,0.575,2.718c8.479,8.416,16.934,16.861,25.362,25.334c4.218,4.246,4.444,9.879,0.632,13.983
	c-3.515,3.924-9.546,4.255-13.47,0.739c-0.164-0.146-0.322-0.299-0.476-0.456c-8.498-8.473-16.99-16.965-25.476-25.476
	c-1.095-1.123-1.887-1.396-3.415-0.745c-11.298,4.718-24.327,2.126-32.958-6.558c-14.632-14.619-29.244-29.237-43.837-43.856
	c-0.673-0.787-1.433-1.496-2.265-2.113c-0.276,0.701-0.648,1.362-1.104,1.963c-35.012,35.056-70.035,70.092-105.072,105.11
	c-3.287,3.463-8.579,4.082-12.577,1.471c-4.47-2.914-5.729-8.9-2.814-13.37c0.325-0.498,0.695-0.965,1.106-1.396
	c0.453-0.509,0.943-0.943,1.443-1.462c32.955-32.937,65.932-65.894,98.931-98.874C263.415,186.989,264.045,186.494,264.698,186.032z
	 M297.345,180.531c0.128,0.244,0.28,0.475,0.453,0.689c15.097,15.097,30.127,30.25,45.29,45.29
	c4.718,4.642,12.059,3.773,15.955-1.538c3.236-4.397,2.736-9.879-1.378-14.003c-15.688-15.726-31.397-31.451-47.13-47.177
	c-0.273-0.283-0.604-0.51-1.047-0.877c-2.246,7.171-6.812,12.587-12.134,17.568L297.345,180.531z M275.332,168.001
	c8.896-0.043,16.146-7.147,16.37-16.04c0.047-9.187-7.362-16.672-16.549-16.719c-0.025-0.001-0.051-0.001-0.076-0.001
	c-9.035-0.142-16.474,7.067-16.615,16.103c-0.143,9.035,7.067,16.474,16.102,16.616c0.25,0.004,0.5,0.002,0.75-0.006
	L275.332,168.001z"
    />
    <path
      fill="#282828"
      d="M346.216,505.276c-1.745,0-3.481,0.066-5.227,0c-1.558-0.075-3.143,0.434-4.567-1.142
	c-0.868-0.943-2.67-1.038-4.02-1.604c-4.896-2.057-8.378-5.916-11.529-9.926c-1.774-2.274-2.473-5.378-3.774-8.021
	c-2.944-5.803-0.943-11.964-1.321-17.927c-0.095-1.698,0.85-3.566,1.661-5.181c1.187-2.55,2.632-4.972,4.312-7.227
	c3.293-4.143,7.209-7.775,12.172-9.747c2.566-1.02,5.123-2.491,8.237-2.095c2.852,0.19,5.712,0.216,8.567,0.075
	c1.88,0.015,3.738,0.396,5.473,1.123c2.361,0.85,4.623,1.954,6.746,3.293c1.5,1.038,2.887,2.255,4.312,3.416
	c3.51,2.964,6.232,6.75,7.926,11.021c1.18,2.83,2.35,5.746,1.953,9.067c-0.196,2.918-0.219,5.845-0.065,8.765
	c0.099,1.728-0.308,3.445-1.171,4.944c-1.472,2.425-2.198,5.294-3.651,7.728c-1.113,1.888-2.896,3.35-4.312,5.058
	c-2.586,3.011-5.918,5.288-9.662,6.604c-1.793,0.661-3.368,2.246-5.661,1.84C350.487,505.136,348.347,505.115,346.216,505.276z
	 M362.832,473.931L362.832,473.931c-0.057-0.559-0.057-1.121,0-1.68c0.547-2.736-0.566-5.18-1.888-7.321
	c-1.334-1.948-2.964-3.677-4.83-5.124c-2.303-1.972-4.944-3.33-8.152-3.113c-1.119,0.07-2.241,0.07-3.359,0
	c-3.218-0.217-5.85,1.18-8.209,3.085c-4.604,3.718-7.548,8.257-6.718,14.54c0.16,1.218,0,2.481,0.066,3.774
	c0.015,0.617,0.126,1.229,0.33,1.812c1.229,2.98,3.173,5.612,5.661,7.662c2.613,2.312,5.482,3.858,9.086,3.698
	c1.491-0.057,3.058,0.265,4.473-0.075c4.035-0.671,7.654-2.879,10.096-6.162c2.18-2.368,3.614-5.085,3.435-8.435
	C362.794,475.639,362.832,474.761,362.832,473.931z"
    />
    <path
      fill="#282828"
      d="M410.962,505.276c-2.67,0-4.671,0-6.652,0c-1.34,0-2.604,0.245-3.84-1.085
	c-0.943-0.943-2.774-1.095-4.18-1.689c-4.906-2.066-8.36-5.973-11.53-9.964c-1.755-2.217-2.236-5.406-3.774-7.85
	c-2.227-3.501-1.15-7.237-1.396-10.87c-0.15-2.11-0.15-4.229,0-6.34c0.16-1.14,0.478-2.251,0.943-3.303
	c0.548-1.472,1.274-2.888,1.784-4.369c1.34-3.887,4.198-6.604,6.925-9.436c2.368-2.532,5.269-4.507,8.492-5.783
	c2.585-1,5.114-2.52,8.237-2.151c3.166,0.208,6.342,0.236,9.511,0.085c1.545-0.158,3.094,0.251,4.359,1.151
	c1.188,0.943,2.934,1.113,4.387,1.735c4.879,2.114,8.351,5.992,11.492,10.021c1.746,2.236,2.444,5.284,3.728,7.907
	c2.896,5.878,1.321,12.115,1.236,18.153c0,2.728-1.888,5.473-3.18,8.114c-1.84,3.869-4.897,6.822-8.059,9.559
	c-2.585,2.227-5.896,3.396-8.878,5.01C417.058,506.012,413.642,504.446,410.962,505.276z M426.814,473.931h0.122
	c-0.113-1.972,0.236-4.142-0.462-5.878c-0.934-2.403-2.368-4.581-4.208-6.388c-3.378-3.256-7.011-5.548-11.917-5.011
	c-0.628,0.054-1.259,0.054-1.887,0c-3.208-0.235-5.774,1.218-8.144,3.104c-4.765,3.774-7.548,8.558-6.604,14.908
	c0.062,0.627,0.062,1.26,0,1.887c-0.227,3.086,1.18,5.558,2.991,7.851c0.918,1.093,1.915,2.118,2.981,3.066
	c2.548,2.378,5.463,3.878,9.067,3.727c1.491-0.065,3.048,0.246,4.463-0.085c2.062-0.449,4.045-1.2,5.888-2.227
	c1.526-1.066,2.901-2.337,4.085-3.773c2.293-2.444,3.822-5.237,3.614-8.737C426.766,475.554,426.814,474.743,426.814,473.931z"
    />
    <path
      fill="#282828"
      d="M566.617,551.236c-0.036-5.362,4.281-9.738,9.644-9.775c5.362-0.036,9.738,4.281,9.775,9.644
	c0.036,5.361-4.281,9.738-9.644,9.774C571.031,560.916,566.655,556.598,566.617,551.236z"
    />
    <path
      fill="#282828"
      d="M272.337,551.208c-0.021,5.362-4.385,9.692-9.747,9.671c-5.362-0.021-9.692-4.385-9.671-9.746
	c0.021-5.362,4.385-9.692,9.746-9.672C268.028,541.482,272.358,545.845,272.337,551.208z"
    />
    <path
      fill="#282828"
      d="M315.664,551.094c0.042-5.362,4.423-9.675,9.785-9.634c5.361,0.042,9.675,4.423,9.633,9.785l0,0
	c-0.041,5.361-4.422,9.675-9.784,9.633C319.936,560.838,315.622,556.457,315.664,551.094z"
    />
    <path
      fill="#282828"
      d="M397.819,551.189c0,5.361-4.347,9.709-9.709,9.709s-9.709-4.348-9.709-9.709l0,0
	c0-5.362,4.347-9.709,9.709-9.709S397.819,545.827,397.819,551.189z"
    />
    <path
      fill="#282828"
      d="M460.555,551.302c-0.062,5.318-4.381,9.604-9.7,9.624c-5.416,0-9.558-4.369-9.766-9.851
	c-0.16-4.256,4.058-9.757,9.945-9.662C456.422,541.508,461.035,546.735,460.555,551.302z"
    />
    <path
      fill="#282828"
      d="M523.3,551.179c0.006,5.362-4.336,9.714-9.698,9.72c-5.362,0.007-9.714-4.335-9.72-9.697
	c0-0.008,0-0.015,0-0.022c0.006-5.362,4.357-9.704,9.72-9.698C518.955,541.488,523.294,545.826,523.3,551.179z"
    />
    <path
      fill="#282828"
      d="M629.354,551.17c0-5.362,4.348-9.709,9.709-9.709c5.362,0,9.709,4.347,9.709,9.709s-4.347,9.709-9.709,9.709
	C633.701,560.879,629.354,556.532,629.354,551.17z"
    />
    <path
      fill="#282828"
      d="M376.909,352.593h25.192c3.435,0,5.397,2.01,5.397,5.463v35.27c0,3.416-2.02,5.481-5.388,5.491h-50.763
	c-2.755,0.186-5.14-1.897-5.325-4.653c-0.017-0.241-0.015-0.483,0.004-0.725c0-11.819,0-23.639,0-35.458
	c0-3.312,2.028-5.369,5.312-5.369C359.864,352.586,368.386,352.58,376.909,352.593z M376.721,384.606c4.85,0,9.709,0,14.559,0
	c1.463,0,2.151-0.405,2.123-2.019c-0.085-4.604-0.065-9.209,0-13.813c0-1.416-0.434-2.038-1.887-2.038c-9.769,0-19.538,0-29.307,0
	c-1.557,0-2.057,0.594-2.028,2.123c0.085,4.415,0.057,8.831,0,13.209c0,2.331,0.085,2.481,2.35,2.481L376.721,384.606z"
    />
  </svg>
)

export default hs
