import React from 'react';
import ManufacPrev from './ManufacPrev/ManufacPrev';
import * as style from './manufac.module.scss';
import smoothscroll from 'smoothscroll-polyfill';
import scrollToEl from '../../scripts/scrollTo';


class Manufac extends React.Component {
  state = {
    showDetails: false,
    height: 0,
    reportRef: null
  };
  topRef = React.createRef();
  reviewPrevClicked = false;

  setRef = (ref) => {
    this.setState({ reportRef:ref });
  }

  trackMoreDetailsClick() {
    if(window && window.gtag) {
      window.gtag('event', 'click', {
        'event_category': 'More Details',
        'event_label': this.props.data.name,
        'value': 1
      });
    }
  }

  animationEndHandler = () => {
    if(this.state.height === 0) {
      this.setState({ showDetails: false });
    } else if(this.reviewPrevClicked) {
      // this.state.reportRef.scrollIntoView({behavior: 'smooth'});
      scrollToEl(this.state.reportRef, 40);
      this.reviewPrevClicked = false;
    }
  }

  toggleDetailsHandler = (e) => {
    if(!this.state.showDetails) {
      this.setState({ showDetails: true });
      this.trackMoreDetailsClick();
      
      // Function triggered by click on sumed up review in ManufacPrev Header
      if(e.target.className.indexOf("ReviewPrevClickArea") > -1) {
        this.reviewPrevClicked = true;
      }
      
    } else {
      this.setState({ height: 0 }); // height animation triggers this.hideDetailsHandler when ended
    }
    
   
    
  }
  componentDidUpdate(_, prevState) {
    if(prevState.showDetails !== this.state.showDetails && this.state.showDetails === true) {
      this.setState( { height: 'auto' });
    }
  }

  componentDidMount() {
    smoothscroll.polyfill();
  }

  getAverageRating(reviewsData) {
    if(reviewsData.length === 0) {
      return 0;
    }

    let total = 0;
    reviewsData.forEach(rev => {
      total += rev.rating.total;
    });
    return Math.round( total / reviewsData.length );
  }

  render() {
    const {data, reviewsData, prevImage} = this.props;
    const averageRating = this.getAverageRating(reviewsData);
    // toDo: gets called 6 times instead of 3 when loading page
    // debugger;
    return  (
      <>
        <div className={style.Manufac} ref={this.topRef}>
          <ManufacPrev 
            data={data} 
            reviewsAmount={reviewsData.length}
            averageRating={averageRating} 
            clickHandler={this.toggleDetailsHandler} 
            show={this.state.showDetails}
            prevImage={prevImage}
            showDetailsClass={style.DetailsOpened}
            reportRef={this.state.reportRef}
          />
          {/* <AnimateHeight
            onAnimationEnd={this.animationEndHandler}
            duration={ 500 }
            height={ this.state.height }
          >
            <ManufacDetail 
              data={data} 
              reviewsData={reviewsData}
              reviewsAmount={reviewsData.length}
              averageRating={averageRating}  
              show={this.state.showDetails}
              detailImage={detailImage}
              sliderImages={sliderImages}
              topRef={this.topRef}
              setRef={this.setRef}
            />
          </AnimateHeight> */}
                      
        </div>
        {this.props.children}
      </>
      );
  }
}
export default Manufac;