import React from 'react';
import * as style from './features.module.scss';

const Features = (props) => {
  // debugger;
  const featureLIs = props.featuresArr.map((feature) =>
    <li key={feature}>{feature}</li>
  );

  return (
    <ul className={[style.Features, 'fontMiddle', 'brownDarkBckg'].join(' ')}>
      <li><strong>Besonderheiten</strong></li>
      {featureLIs}
    </ul>
  );
}
export default Features;