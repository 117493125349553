import React from 'react';
import InfoButton from '../../../UI/InfoButton/InfoButton';
import * as style from './Basics.module.scss';
import imgTick from '../../../../images/icons/tick-mark.svg';
import imgCross from '../../../../images/icons/cross.svg';
import AnimatedSize from './AnimatedSize/AnimatedSize';

const Basics = ({clickHandler, data}) => {
  const NA = 'k.A.';
  const setClass = (val) => {
    // if(data.name === "TinyHouseDesign") debugger;
    return ( typeof val === "undefined" || val ==='' ? style.NA : '')
  };
  const checkMark = (val)  => {
    if(typeof val === "undefined" || val ==='') {
      return NA;
    } else {
      return (val ? 
        <img alt="vorhanden" src={imgTick} className={style.CheckedSymbol} /> : 
        <img alt="nicht vorhanden" src={imgCross} className={style.NotCheckedSymbol} />);
    }
  }

  return (
    <div className={style.Basics}>
      <ul className={['fontMiddle', 'blackBor', 'brownDarkBckg'].join(' ')}>
        <li><strong>Allgemeines</strong></li>
        <li className={setClass(data.length || data.useSpace)}>
          <AnimatedSize data={data} NA={NA}/>
        </li>
        <li className={setClass(data.customMade)}>
          <span className="brownDarkBckg">Maßgefertigte Häuser</span>
          <span className="brownDarkBckg">{checkMark(data.customMade)}</span>
        </li>
        <li className={setClass(data.modularDesign)}>
          <span className="brownDarkBckg">Baukastensystem</span> 
          <span className="brownDarkBckg">{checkMark(data.modularDesign)}</span>
        </li>
        <li className={setClass(data.buildingShell)}>
          <span className="brownDarkBckg">Rohbau-Anfertigung</span>
          <span className="brownDarkBckg">{checkMark(data.buildingShell)}</span>
        </li>
        <li className={setClass(data.autarky)}>
          <span className="brownDarkBckg">(Teil-)Autarkie möglich</span>
          <span className="brownDarkBckg">{checkMark(data.autarky)}</span>
        </li>
      </ul>
      <div className={[style.Info, 'fontSmall'].join(' ')}>
          <InfoButton clickHandler={clickHandler} className={style.SmallInfoButton}>
            Erklärung zu den Punkten
          </InfoButton>
        </div>
    </div>
  );
};
export default Basics;