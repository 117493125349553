import React from 'react';
import Img from 'gatsby-image';
import StarRatings from 'react-star-ratings';
import * as  style from './MiniPrev.module.scss';
import { Link } from 'gatsby';
import { replaceUmlautWhitspace } from '../../../../scripts/helper';
import MiniBasics from './MiniBasics/MiniBasics';

export default (props) => {

    const getAverageRating = (reviewsData) => {
        if(reviewsData.length === 0) {
            return 0;
        }

        let total = 0;
        reviewsData.forEach(rev => {
            total += rev.rating.total;
        });
        return Math.round( total / reviewsData.length );
    }
    const averageRating = getAverageRating(props.manufacReview);

    const averageStars = null;
        // <StarRatings
        // starDimension="12px"
        // starSpacing="1px"
        // rating={averageRating}
        // starRatedColor="rgb(145, 73, 66)"
        // starEmptyColor="grey"
        // numberOfStars={5}
        // />;
    
    const products = props.data.products.map((product, i) =>
        <li key={i}><span className={[style.Bullet, "blackBckg"].join(' ')}></span><span>{product}</span></li>
      );

    const manufacNameUrl = replaceUmlautWhitspace(props.data.name.toLocaleLowerCase());
    const reviewsAmount = props.manufacReview.length;
    const reportsString = `Erfahrungsbericht${reviewsAmount>1 || reviewsAmount === 0 ? 'e' : ''}`;

    return (
        <>
            <div className={[style.HeadArea, 'fontMiddle'].join(' ')}>
                <h3 className={style.Name}>{props.data.name}</h3>
                <div className={style.ReviewPrev} >
                    {averageStars} 
                    <Link 
                    to={`/hersteller/${manufacNameUrl}/#hersteller-review`}
                    className={style.ReviewPrevClickArea}
                    > 
                    {reviewsAmount} {reportsString}
                    </Link>
                </div>
                <div className={style.Line}></div>
                <ul className={style.Products}>{products}</ul>
            </div>
            <div className={style.BodyArea}>
                <div className={style.PrevImageWrapper} >
                    <Img fluid={props.manufacPrevImage} />
                    <Link 
                        to={`/hersteller/${manufacNameUrl}/#top`}
                        className={[style.Button, "fontMiddle"].join(' ')}
                        > 
                        {/* <span className={style.linkArrow}>⦊ </span> */}
                        <span>Zur Detailseite</span>
                    </Link>
                </div>
                <MiniBasics data={props.data} />
                <Link 
                    to={`/hersteller/${manufacNameUrl}/#top`}
                    className={[style.Button, style.ButtonMobile, "fontMiddle", "linkColor"].join(' ')}
                > 
                    <span className={style.linkArrow}>⦊ </span>
                    <span>Zur Detailseite</span>
                </Link>
            </div>
        </>
    );
};