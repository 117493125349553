import React from 'react';

const ExplanationTxt = ({ Headline }) => (
  <>
    <p><span className={Headline}>Längen/Nutzflächen</span> 
      Welche Tiny House Größen bietet der Hersteller unter anderem an? </p>
    <p>
    <span className={Headline}>Maßgefertigte Häuser</span> 
      Fertigt der Hersteller auch individuelle Häuser, die
      du selbst entworfen hast?
    </p>
    <p><span className={Headline}>Baukastensystem</span> 
      Kannst du einen fertigen Tiny-House-Baukasten erwerben, mit
      dem du relativ einfach den Rohbau deines Tiny Houses aufbauen kannst?
    </p>
    <p><span className={Headline}>Rohbau-Anfertigung</span>
      Kannst du dir vom Händler nur den Rohbau deines Hauses
      anfertigen lassen, um den Rest selbst fertig zu bauen? 
    </p>
    <p><span className={Headline}>(Teil-)Autarkie möglich</span>
      Bietet der Hersteller auch Tiny Houses an, die ein zumindest
      teilweises, autarkes Wohnen ermöglichen?
    </p>
    <p><span className={Headline}><i>Weiteres</i></span>
    <strong>k.A.</strong> steht für "keine Angabe". Das heißt, hierzu fehlen uns noch informationen.
    </p>
  </>
);

export default ExplanationTxt;