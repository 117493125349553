import React from "react";
import Layout from "../components/Layout/Layout";
import { graphql } from "gatsby";
import manufacData from '../data/manufac-data-node';
import manufacReviews from '../data/manufac-reviews';
import Manufac from "../components/Manufac/Manufac";
import { Link } from "@reach/router";
import { getGraphQLimage } from '../scripts/herstellerPage/getGraphQLimage.js';
import WriteReportSvg from '../images/icons/write-report';
import ManufacEntrySvg from '../images/icons/hersteller-icon';
import * as style from '../styles/hersteller.module.scss';
import Select from 'react-select';
import ManufacMap from "../components/Manufac/ManufacMap/ManufacMap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap } from '@fortawesome/free-regular-svg-icons'
import { faStream } from '@fortawesome/free-solid-svg-icons'
import Button from "../components/UI/Button/Button";
import Seo from "../components/SEO/SEO";
import AdSense from 'react-adsense';
import AdSenseRerenderer from "../components/AdSenseRerenderer/AdSenseRerenderer";
import { createId } from "../scripts/helper";

const mapOptions = {
  position: [51.415290, 10.004434],
  zoom: 5,
}

export default class Hersteller extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
      // initialLoad due to strange manufac preview layout bug
      // other guy who has same problem: https://github.com/gatsbyjs/gatsby/issues/9121
      // initialLoad: this.props.location.key === "initial" ? true : false, 
      initialLoad: typeof window !== "undefined" && window.GATSBY_INITIALIZED ? false : true, 
      selectedManufac: null,
      mapPosition: mapOptions.position,
      mapZoom: mapOptions.zoom,
      mapViewActive: (typeof window ==="object" && window.MAP_VIEW_ACTIVE) || false 
    };
  
    this.options = manufacData.map((manufac, i) => {
      return { value: manufac.name, label: manufac.name, index: i}
    });
  }

  handleChange = selectedManufac => {
    if(selectedManufac) {
      this.setState({ 
          mapPosition: manufacData[selectedManufac.index].coordinates,
          mapZoom: 12
        });
    } else {
      this.setState({ 
          mapPosition: mapOptions.position,
          mapZoom: mapOptions.zoom
        });
    }

    this.setState({ selectedManufac });
  };

  handleMapButton = () => {
    if(window && window.gtag) { 
      window.gtag('event', 'click', {
        'event_category': 'Manufac Map Button',
        'event_label': this.state.mapViewActive,
        'value': 1
      }); 
    }
    
    this.setState((prevState) => {
       return { mapViewActive: !prevState.mapViewActive }
    });

    if(window.MAP_VIEW_ACTIVE) {
      window.MAP_VIEW_ACTIVE = false;
    } else {
      window.MAP_VIEW_ACTIVE = true;
    }
  };

  componentDidMount() {
    //workaround because of strange layout bug
    if(this.state.initialLoad) {
      console.log("%creload manufacPrevs'", "color: blue");
      this.setState({initialLoad: false});
    } 
  }


  render() {
    const { props } = this;
    const { data } = props;
    const { ReportImg, ManufacEntryImg, EndLinks, EndLink, AmountNote, ManufacSelect, ManufacSelectedNote } = style;
    // nested looping in getGraphQLimage find inside map) is pretty inefficient and increase building time 
    //-> improve (e.g. load manufac data also via graphql so the same order can be achieved)
    const manufacPrevImages = {};

    const mData = manufacData.sort( (a,b) => {
      try {
        return (manufacReviews[a.name].length - manufacReviews[b.name].length) * -1;
      } catch(error) {
        console.log(error);
        console.error('manufacReviews:', manufacReviews);
      }
    });

    const manufacs = mData.map((manufac, i) => {
      const imagesObj = getGraphQLimage(data, manufac, manufacReviews);
      manufacPrevImages[manufac.name] = imagesObj.prevImage;
      
      if(this.state.selectedManufac && (this.state.selectedManufac.value !== manufac.name)) {
        return null;
      }
      return (
  
        <Manufac 
          key={i} 
          data={manufac} 
          reviewsData={manufacReviews[manufac.name]} 
          prevImage={imagesObj.prevImage}
          detailImage={imagesObj.detailImage}
          sliderImages={imagesObj.sliderImages}
        >
          {i%6 === 0 && i !== 0 ?
          <AdSenseRerenderer maxWidth={700}>
            <AdSense.Google
                key={`adSense_${createId(15)}_${i}`} //key needed in order to load new ad on new "pageload"
                style={ {display:'block', 
                  borderRadius: '20px', 
                  borderTopRightRadius: '5px', 
                  // overflow: 'hidden' 
                }}
                format="fluid"
                layout-key="-dg+8w+25-ml+yn"
                client="ca-pub-2126121658624128"
                slot="8224623777"
            /> 
          </AdSenseRerenderer> : null
          } 
        </Manufac>
      )
      
    }); // *** End manufacData.map ***

    // manufacs.sort( (a,b) => {
    //   return (a.props.reviewsData.length - b.props.reviewsData.length) * -1;
    // });

    // debugger;

    // const website_title = 'Hersteller für Tiny Houses finden | TinyHouseHelden' ;
    // const meta_description = 'Finde hier deinen passenden Tiny House Hersteller an Hand von Erfahrungsberichten. ' +
    // 'Du erhältst alle wichtige Angaben auf einen Blick!'

    // // as long as content is not loaded via wordpress api
    // const yoast_og_meta = [
    //   {property: 'og:locale', content:'de_DE'},
    //   {property: 'og:type', content:'website'},
    //   {property: 'og:title', content: website_title},
    //   {property: 'og:description', content:meta_description},
    //   {property: 'og:url', content:'https://www.tiny-house-helden.de/hersteller/'},
    //   {property: 'og:site_name', content:'Tiny House Helden'},
    // ];
    // const params = this.props.pageContext.params ? this.props.pageContext.params : {}

    // debugger;
    return (
      <>
      {/* <Seo 
        title={website_title}
        meta_description={meta_description}
        yoast_og_meta={yoast_og_meta}
      /> */}
        <Layout 
          headlines={["Tiny House Hersteller"]}
          // params
        >
          <div className="centeredTextArea">
            <h1>Finde den passenden Tiny House Hersteller!</h1>
            <p className="XXXtwoColP">
              Hier findest du eine Übersicht an Tiny House Herstellern bzw. Anbietern. Wir arbeiten hierbei völlig unabhängig. Das 
              heißt, es wird kein Tiny House Anbieter von uns bevorzugt oder Ähnliches.<br /><br />{" "}
              <strong>Bitte beachte</strong>: Wir erstellen die Einträge nach bestem Wissen und Gewissen, können dennoch
              nicht ausschließen, dass sich Fehler eingeschlichen haben. Wir bieten aber jedem Hersteller grundsätzlich die Möglichkeit,
              uns <Link to='/kontakt/'>Fehler mitzuteilen</Link> oder einen {" "}
              <Link to="/hersteller-services/">neuen Eintrag</Link>
              {" "}zu erstellen.
            </p>
            <h2>Echte Hersteller-Erfahrungsberichte</h2>
            <p className="">Zu jedem Tiny House Hersteller kann hier ein Erfahrungsbericht mit Bewertung verfasst werden.
            Das kann bei der Suche nach dem richtigen Anbieter enorm hilfreich sein. Solltest du also jemanden kennen, der Erfahrungen gesammelt hat (egal ob positive
            oder negative), dann hilf der Tiny House Community, indem du die Person bittest, hier einen Erfahrungsbericht zu schreiben. 
            Übrigens können auch Hersteller bewertet werden, die noch nicht in dieser Auflistung erscheinen. Klicke dazu hier:{" "}
            <Link to='/hersteller/erfahrungsbericht-verfassen/'>Erfahrungsbericht zu ungelistetem Hersteller schreiben</Link></p>

            <p className={AmountNote}>Aktuell befinden sich <strong>{manufacData.length} </strong>  
             Hersteller in dieser Übersicht. <br /> <br /><br/> 
             {/* Zuletzt hinzugefügter Hersteller: {manufacData[manufacData.length-1].name} am {manufacData[manufacData.length-1].added} */}
            </p>
          </div>
        
          {/* https://react-select.com */}
          <h3><FontAwesomeIcon icon={faMap} /> Hersteller-Karte</h3>
          <p>Auf der nachfolgenden Karte findest du alle eingetragenen Hersteller. Die gleichen Tiny House Hersteller findest du auch weiter unten
            als Liste dargestellt.
          </p>

          {typeof window !== 'undefined' &&
              <ManufacMap
                // data={manufac} 
                manufacReviews={manufacReviews} 
                manufacPrevImages={manufacPrevImages}
                manufacData={manufacData}
                position={this.state.mapPosition}
                zoom={this.state.mapZoom}
                standardPosition={mapOptions.position}
                standardZoom={mapOptions.zoom}
              />
          }
          <p><br /></p>
           <h3><FontAwesomeIcon icon={faStream} /> Hersteller-Liste</h3>
           <div className={style.PanelBar}>
            <Select
              value={this.state.selectedManufac}
              onChange={this.handleChange}
              options={this.options}
              isClearable={true}
              placeholder="Schnellzugriff: Hersteller..."
              className={ManufacSelect}
              // isDisabled
            />
            {false && <Button style={{display:"none"}} color="white" type="narrow" className={style.MapButton}>
              <div onClick={this.handleMapButton}>
                {!this.state.mapViewActive ? 
                  <>
                  <FontAwesomeIcon icon={faMap} />
                  <span className={style.ButtonText}>Zur Karte wechseln
                    {/* <span>nansicht</span> */}
                  </span>
                  </>
                  :
                  <>
                  <FontAwesomeIcon icon={faStream} />
                  <span className={style.ButtonText}>Zur Liste wechseln
                    {/* <span>nansicht</span> */}
                  </span>
                  </>
                }
                
              </div>
            </Button> }
          </div>
           
          {!this.state.mapViewActive && !this.state.initialLoad && manufacs}
          {this.state.selectedManufac && !this.state.mapViewActive ? <p className={ManufacSelectedNote}>Hinweis: <i>Du hast über den Schnellzugriff einen 
            bestimmten Hersteller ausgewählt. Entferne den Namen aus der Schnellzugriff-Leiste
            um wieder alle Hersteller zu sehen.</i> </p> : null}
          
          <p><strong>Dieser Bereich wird ständig aktualisiert</strong>. Es lohnt sich also, wieder vorbeizuschauen.</p>

          <h2>Mehr zum Thema Tiny House Hersteller</h2>
          <p>Solltest du schon ein paar Tiny House Hersteller ins Auge gefasst haben, empfehlen wir dir folgenden Blog-Artikel:
              <a href="https://tiny-house-helden.de/blog/fragen-an-tiny-house-hersteller/" target="_blank"> Fragen an Tiny House Hersteller</a>
          </p>
          <p>Außerdem kannst du nachfolgend einen Erfahrungsbericht verfassen oder sogar selbst einen hier noch nicht gelisteten Hersteller hinzufügen 👇</p>




          <div className={["fontMiddle", EndLinks].join(' ')}>
            <Link 
              to='/hersteller/erfahrungsbericht-verfassen/'
              className={EndLink}
            >
              <p><strong>Erfahrungsbericht verfassen</strong></p>
              <span><WriteReportSvg className={ReportImg}/></span>
              <span>Du möchtest einen Erfahrungsbericht verfassen, 
                hast den Hersteller hier aber (noch) nicht gefunden?
                Dann verfasse hier einen neuen Erfahrungsbericht!
              </span>
            </Link>
            <Link
              className={EndLink}
              to="/hersteller-services/"
            > 
              <p><strong>Neuen Hersteller hinzufügen</strong></p>
              <span><ManufacEntrySvg className={ManufacEntryImg}/></span>
              <span>Sie sind Hersteller und möchten sich gerne bei uns eintragen? 
                Hier erhalten Sie weitere Informationen.</span>
            </Link>
          </div>
        </Layout>
      </>
    );
  }
}

export const query = graphql`
query manufacImages{
  allFilePrev:allFile(filter: { relativePath: {regex: "/images/manufacs/.+/mainPrev/"} }) {
    prevImgArr: nodes {
      name
      relativeDirectory
      ...fluidImagePreview
    }
  }
  
  allFileSlider: allFile(filter: {relativePath: {regex: "/images/manufacs/.+/detail/"}}, 
    sort: { fields: [name] order: ASC}) {
    
    imagesGroup: group(field: relativeDirectory) {
      sliderImgArr: edges {
       sliderImg: node {
          name
          relativeDirectory
          ...fluidImageDetailCollection
        }
      
      }
    }
  }

  placeholderPreview: file(relativePath: {regex: "/images/placeholder/"}) {
    ...fluidImagePreview
  }
  # toDo: maybe other placholder pic for detail component
  placeholderDetail: file(relativePath: {regex: "/images/placeholder/"}) {
    ...fluidImageDetailCollection
  }
}`

export const fluidImagePreview = graphql`
fragment fluidImagePreview on File {
  childImageSharp {
    fluid(maxWidth: 470, quality: 90) {
      ...GatsbyImageSharpFluid_withWebp
    }
  }
}`;

export const fluidImageDetailCollection = graphql`
fragment fluidImageDetailCollection on File {
  childImageSharp {
    fluid(maxWidth: 840, quality: 80) {
      ...GatsbyImageSharpFluid_withWebp
    }
  }
}`;


export function Head() {
  const website_title = 'Hersteller für Tiny Houses finden | TinyHouseHelden' ;
    const meta_description = 'Finde hier deinen Tiny House Hersteller an Hand von echten Erfahrungsberichten. ' +
    'Tiny House Diekmann · Koersmann GmbH · und viele mehr!'

    // as long as content is not loaded via wordpress api
    const yoast_og_meta = [
      {property: 'og:locale', content:'de_DE'},
      {property: 'og:type', content:'website'},
      {property: 'og:title', content: website_title},
      {property: 'og:description', content:meta_description},
      {property: 'og:url', content:'https://www.tiny-house-helden.de/hersteller/'},
      {property: 'og:site_name', content:'Tiny House Helden'},
    ];
  return (
    <Seo 
      title={website_title}
      meta_description={meta_description}
      yoast_og_meta={yoast_og_meta}
    />
  )
}