import React from 'react';

const wr = ({className}) => (

  <svg version="1.1" className={className} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    width="579.738px" height="566.383px" viewBox="185.923 20.302 579.738 566.383"
    enableBackground="new 185.923 20.302 579.738 566.383">
  <g>
    
      <rect x="198.694" y="32.993" transform="matrix(1 0.001 -0.001 1 0.3043 -0.3904)" fill="none" stroke="#000000" strokeWidth="25" strokeLinejoin="round" strokeMiterlimit="10" width="382" height="540.999"/>
    <g>
      
        <line fill="none" stroke="#000000" strokeWidth="25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="254.278" y1="127.993" x2="527.278" y2="127.993"/>
      
        <line fill="none" stroke="#000000" strokeWidth="25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="254.278" y1="196.111" x2="527.278" y2="196.111"/>
      
        <line fill="none" stroke="#000000" strokeWidth="25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="254.278" y1="264.23" x2="527.278" y2="264.23"/>
      
        <line fill="none" stroke="#000000" strokeWidth="25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="254.278" y1="332.347" x2="527.278" y2="332.347"/>
      
        <line fill="none" stroke="#000000" strokeWidth="25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="254.278" y1="400.467" x2="527.278" y2="400.467"/>
      
        <line fill="none" stroke="#000000" strokeWidth="25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="254.278" y1="468.584" x2="470.695" y2="468.584"/>
    </g>
    <polygon fill="#e1d7bf" stroke="#000000" strokeWidth="25" strokeLinejoin="round" strokeMiterlimit="10" points="
      699.664,62.443 537.158,388.693 524.947,472.535 586.477,421.636 753.161,89.091 	"/>
  </g>
  </svg>

);

export default wr;