import React from 'react';
import {MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import Control from 'react-leaflet-control'; // DISABLED (no react 18 Version => it was just one button (centered view))
import * as style from './ManufacMap.module.scss';
import MiniPrev from './MiniPrev/MiniPrev';
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faGlobeEurope } from '@fortawesome/free-solid-svg-icons';
import LeafletAssetProvider from "../../LeafletAssetProvider/LeafletAssetProvider";
// import '../../../styles/leaflet.css';

class ManufacMap extends React.Component {

  state={
    mapPosition: typeof window ==="object" && window.MAP_POSITION ? window.MAP_POSITION : this.props.position,
    mapZoom: typeof window ==="object" && window.MAP_ZOOM ? window.MAP_ZOOM : this.props.zoom,
  }

  timeOut = null;

  ManufacMapLoadedHandler = () => {
    // const LeaFletLink = document.querySelector('.leaflet-control-attribution > a:first-of-type');
    // LeaFletLink.setAttribute('target','_blank');
  }
  handleViewportChange = (viewport) => {
    window.MAP_POSITION = viewport.center;
    window.MAP_ZOOM = viewport.zoom;

  }
  handleMapOverviewButton = () => {
    // dirty way to force update of state since this.forceUpdate doesn't work
    // why force? Moving map doesn't change state -> nothing happens when clicking on button
    // why not setState in handleVieportChange: stackoverflow if user clicks on marker (too many stateChanges)
    this.setState({
      mapPosition: null,
      mapZoom: null
      }, () => {
       this.setState( {
        mapPosition: this.props.standardPosition,
        mapZoom: this.props.standardZoom})
      })
  }
  componentDidUpdate(prevProps){
    const positionChanged = 
          prevProps.position[0] !== this.props.position[0] &&  prevProps.position[1] !== this.props.position[1];
    const zoomChanged = prevProps.zoom !== this.props.zoom;
    if(positionChanged || zoomChanged) {
      this.setState({
        mapPosition: this.props.position,
        mapZoom: this.props.zoom
      });
    }
  }
  componentDidMount() {
    void GestureHandling; // calm down eslint (... is defined but never used)
    //LeaFlet onLoad Event doesn't fire so quick&dirty solution for now...
    this.timeOut = window.setTimeout(()=>{
      const LeaFletLink = document.querySelector('.leaflet-control-attribution > a:first-of-type');
      LeaFletLink.setAttribute('target','_blank');
    }, 3500);

  }

  componentWillUnmount() {
    window.clearTimeout(this.timeOut);
  }

  render() {

    const markers = this.props.manufacData.map((manufac, i) => {
      // debugger;
      return(
        <Marker position={manufac.coordinates} key={i}>
          <Popup>
            <MiniPrev
              manufacReview={this.props.manufacReviews[manufac.name]}
              manufacPrevImage={this.props.manufacPrevImages[manufac.name]}
              data={manufac}
            />
          </Popup>
        </Marker>
      )
    });
      return (
        <>
        
        <div id="ManufacMapAnchor" className={style.ManufacMapAnchor}></div>
        {typeof window !== 'undefined' ?
        <>
          <LeafletAssetProvider />
          <MapContainer 
            gestureHandling={true}
            gestureHandlingOptions={{
              duration: 2000 //2 secs
            }}
            gestureHandlingText={{
              touch: "Zwei Finger zum Bewegen oder Zoomen innerhalb Karte",
              scroll: "STRG-Taste gedrückt halten um Karte zu zoomen",
              scrollMac: "\u2318 gedrückt halten um Karte zu zoomen"
            
            }}
            center={this.state.mapPosition} 
            zoom={this.state.mapZoom}
            onLoad={this.ManufacMapLoadedHandler}
            useFlyTo={true}      
            onViewportChanged={(viewport) => {this.handleViewportChange(viewport)}}  
          >
            <TileLayer
              url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
              attribution="&copy; <a target=&quot;_blank&quot; href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            />
            {/* <Control position="topleft" >
              <div className={style.OverviewButton} onClick={ this.handleMapOverviewButton } >
                <FontAwesomeIcon className={style.OverViewIcon} icon={faGlobeEurope} />
              </div>
            </Control> */}
            {markers}
          </MapContainer>
          </> : null }
        </>
      );
  }
}

export default ManufacMap;

/* 
Get LAT and LON from address: https://stackoverflow.com/questions/15919227/get-latitude-longitude-as-per-address-given-for-leaflet
example (TechTinyHouse):
$.get(location.protocol + '//nominatim.openstreetmap.org/search?format=json&q=Köpfertstr. 23, 70599 Stuttgart', function(data){
      console.log(data);
  });
*/