// extracted by mini-css-extract-plugin
export var Anchor = "manufacPrev-module--Anchor--d6c27";
export var Bullet = "manufacPrev-module--Bullet--7b0c3";
export var Button = "manufacPrev-module--Button--8292e";
export var HeadArea = "manufacPrev-module--HeadArea--e2d97";
export var Line = "manufacPrev-module--Line--b6ee4";
export var ManufacPrev = "manufacPrev-module--ManufacPrev--5edef";
export var Name = "manufacPrev-module--Name--96b15";
export var Products = "manufacPrev-module--Products--037d3";
export var ReviewPrev = "manufacPrev-module--ReviewPrev--30537";
export var ReviewPrevClickArea = "manufacPrev-module--ReviewPrevClickArea--9e98e";
export var blackBckg = "manufacPrev-module--blackBckg--1044f";
export var blackBor = "manufacPrev-module--blackBor--0e4c0";
export var blackCol = "manufacPrev-module--blackCol--e0eb1";
export var brownDarkBckg = "manufacPrev-module--brownDarkBckg--37ffa";
export var brownLightBckg = "manufacPrev-module--brownLightBckg--d0c84";
export var fontMiddle = "manufacPrev-module--fontMiddle--f8407";
export var fontNormal = "manufacPrev-module--fontNormal--a5ab4";
export var fontSmall = "manufacPrev-module--fontSmall--117a2";
export var greenCol = "manufacPrev-module--greenCol--f8175";
export var greyBckg = "manufacPrev-module--greyBckg--51ed3";
export var greyCol = "manufacPrev-module--greyCol--2ebf1";
export var linkArrow = "manufacPrev-module--linkArrow--d7bea";
export var notClickable = "manufacPrev-module--notClickable--e545c";
export var redCol = "manufacPrev-module--redCol--487b1";
export var whiteCol = "manufacPrev-module--whiteCol--5356b";